.dot {
  border-radius: 50%;
  display: inline-block;
  margin: 5px;
  height: 20px;
  width: 20px;
}

.filled {
  background-color: var(--accent);
}

.empty {
  background-color: var(--section-color);
}
