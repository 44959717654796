#personal-info {
  background-image: url("/images/background.JPG"),
    linear-gradient(
      to left,
      rgb(100, 100, 100),
      rgb(70, 70, 70),
      rgb(190, 190, 190)
    );
  background-repeat: no-repeat;
  color: var(--main-text);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 360px;
}

.name-info {
  background-image: linear-gradient(
    rgb(255, 255, 255, 1),
    rgb(255, 255, 255, 0)
  );
  padding: 0px 10px;
  padding-bottom: 40px;
}

.professional-info {
  align-items: center;
  background-image: linear-gradient(
    rgb(255, 255, 255, 0),
    rgb(255, 255, 255, 1)
  );
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 40px;
}

.professional-info p {
  align-items: center;
  display: inline-flex;
  font-weight: bold;
  min-width: 16rem;
}

.professional-info a {
  display: inline-block;
}

.links {
  display: flex;
  flex: 1;
  justify-content: space-around;
}

.links img {
  max-height: 2.5rem;
}

.contact-info {
  display: flex;
  flex: 3;
  flex-wrap: wrap;
  justify-content: space-around;
}

@media (min-width: 980px) {
  #personal-info {
    background-position: center;
  }
}
