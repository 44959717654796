/****************************************** Global *****************************************************/
:root {
  --accent: rgb(228, 155, 72);
  --background: rgb(200, 200, 200);
  --header: rgb(11, 79, 119);
  --main-text: rgb(0, 0, 0);
  --darker-accent: rgb(180, 118, 47);
  --section-color: rgb(230, 230, 230);
}

/****************************************** Nav **************************************************************/
nav {
  background-color: var(--header);
  padding: 0;
  margin: 0;
  text-align: right;
  width: 100%;
}

nav a {
  color: var(--section-color);
  margin: 10px;
}

nav a:hover {
  color: gray;
}

.nav-menu {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 1.3rem;
  justify-content: space-between;
  padding: 5px;
}

/****************************************** Body *********************************************************/

body {
  background-color: var(--background);
  color: var(--main-text);
  font: 1rem;
  margin: 0;
  padding: 0;
}

/****************************************** General ***********************************************/

a {
  color: var(--darker-accent);
  font-weight: bold;
  text-decoration: none;
}

a:hover {
  color: var(--accent);
  text-decoration: none;
}

p {
  margin: 0;
  padding: 0;
}

ul {
  padding: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 10px;
}

img {
  margin: 10px;
}

/****************************************** Section ***********************************************/

.section {
  background-color: var(--section-color);
  color: var(--main-text);
  padding: 20px;
  overflow: hidden;
  text-align: left;
}

/****************************************** Tile ***********************************************/

.tile {
  background-color: var(--section-color);
  border-radius: 7px;
  color: var(--main-text);
  float: left;
  margin: 15px;
  padding: 20px;
  position: relative;
  overflow: hidden;
  text-align: left;
  width: auto;
  max-width: 500px;
}

/***************************************** Footer **********************************************/

footer {
  background-color: var(--header);
  color: var(--section-color);
  display: flex;
  justify-content: space-around;
}
