hr {
  border: 1px solid var(--nav-bar);
  width: 75%;
}

.accent-bar {
  background-color: var(--accent);
  height: 20px;
  margin: 0px;
  width: 100%;
}

.info-section-control {
  background-color: var(--header);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.info-section-control button {
  color: var(--section-color);
  font-size: large;
  font-weight: bold;
  max-width: 10rem;
}

.current-button {
  color: var(--accent) !important;
}

.summary {
  background-color: var(--section-color);
  padding: 2rem;
}

main {
  display: flex;
  flex-direction: column;
}

h1 {
  font-size: 4rem;
}

.info {
  background-color: var(--main);
  transition: max-height 0.3s ease-out;
  margin: auto;
  max-width: 90%;
  max-height: 50rem;
}

.info hr {
  background-color: var(--accent);
  height: 3px;
  margin: 0px;
  width: 100%;
}

.hide {
  max-height: 0;
  overflow: hidden;
}

button {
  background-color: rgb(0, 0, 0, 0);
  border: none;
  margin: 20px;
  outline: 0 none;
  text-align: left;
}
