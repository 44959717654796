body {
  margin: 0;
  font-family: "Avenir", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--header);
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  font-weight: bold;
  margin: 8px 0px;
}
